import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import { User, Banknote, Smartphone, IndianRupee, Edit2, Check, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
const Bank = Banknote;
const PaymentDetailsPage = () => {
  const [formData, setFormData] = useState({
    accountHolderName: '',
    accountNumber: '',
    ifscCode: '',
    bankName: '',
    upiId: '',
    mobileNumber: ''
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [hasExistingDetails, setHasExistingDetails] = useState(false);

  useEffect(() => {
    fetchExistingDetails();
  }, []);

  const fetchExistingDetails = async () => {
    try {
      const docRef = doc(firestore, 'paymentDetails', auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        setFormData(docSnap.data());
        setHasExistingDetails(true);
        setIsEditing(false);
      } else {
        setIsEditing(true); // Enable editing mode for new users
      }
      setLoading(false);
    } catch (err) {
      console.error('Error fetching payment details:', err);
      setLoading(false);
      setError('Failed to load existing details');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const validateForm = () => {
    if (!formData.accountHolderName.trim()) return 'Account holder name is required';
    if (!formData.accountNumber.trim()) return 'Account number is required';
    if (!formData.ifscCode.trim()) return 'IFSC code is required';
    if (!formData.bankName.trim()) return 'Bank name is required';
    if (!formData.mobileNumber.trim()) return 'Mobile number is required';
    if (formData.mobileNumber.length !== 10) return 'Invalid mobile number';
    if (formData.upiId && !formData.upiId.includes('@')) return 'Invalid UPI ID';
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    setSaving(true);
    setError('');
    
    try {
      await setDoc(doc(firestore, 'paymentDetails', auth.currentUser.uid), {
        ...formData,
        updatedAt: new Date().toISOString()
      });
      
      setSuccess(true);
      setIsEditing(false);
      setHasExistingDetails(true);
      setTimeout(() => setSuccess(false), 3000);
    } catch (err) {
      console.error('Error saving payment details:', err);
      setError('Failed to save payment details');
    } finally {
      setSaving(false);
    }
  };

  const DetailRow = ({ label, value }) => (
    <div className="py-3 border-b border-slate-100 last:border-0">
      <p className="text-sm text-slate-500 mb-1">{label}</p>
      <p className="text-base font-medium text-slate-800">{value || '-'}</p>
    </div>
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-slate-50 to-blue-50">
        <div className="w-16 h-16 border-4 border-blue-200 border-t-blue-600 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 via-blue-50 to-purple-50 p-4 sm:p-6 md:p-8">
      <div className="max-w-2xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white/90 backdrop-blur-sm rounded-2xl p-6 sm:p-8 shadow-xl border border-white/20"
        >
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-slate-800">Payment Details</h2>
            {hasExistingDetails && !isEditing && (
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setIsEditing(true)}
                className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-blue-600 bg-blue-50 rounded-lg hover:bg-blue-100 transition-colors"
              >
                <Edit2 size={16} />
                Edit Details
              </motion.button>
            )}
          </div>

          <AnimatePresence mode="wait">
            {isEditing ? (
              <motion.form 
                key="edit-form"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onSubmit={handleSubmit} 
                className="space-y-6"
              >
                {/* Bank Details Section */}
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold text-slate-700 flex items-center gap-2">
                    <Bank size={20} className="text-blue-600" />
                    Bank Account Details
                  </h3>
                  
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-slate-700 mb-1">
                        Account Holder Name
                      </label>
                      <input
                        type="text"
                        name="accountHolderName"
                        value={formData.accountHolderName}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 bg-slate-50/50 border border-slate-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-slate-700 mb-1">
                        Account Number
                      </label>
                      <input
                        type="text"
                        name="accountNumber"
                        value={formData.accountNumber}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 bg-slate-50/50 border border-slate-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-slate-700 mb-1">
                        IFSC Code
                      </label>
                      <input
                        type="text"
                        name="ifscCode"
                        value={formData.ifscCode}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 bg-slate-50/50 border border-slate-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-slate-700 mb-1">
                        Bank Name
                      </label>
                      <input
                        type="text"
                        name="bankName"
                        value={formData.bankName}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 bg-slate-50/50 border border-slate-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>

                {/* UPI and Mobile Section */}
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold text-slate-700 flex items-center gap-2">
                    <IndianRupee size={20} className="text-blue-600" />
                    UPI & Mobile Details
                  </h3>
                  
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-slate-700 mb-1">
                        UPI ID (Optional)
                      </label>
                      <input
                        type="text"
                        name="upiId"
                        value={formData.upiId}
                        onChange={handleInputChange}
                        placeholder="example@upi"
                        className="w-full px-4 py-2 bg-slate-50/50 border border-slate-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-slate-700 mb-1">
                        Mobile Number
                      </label>
                      <input
                        type="tel"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleInputChange}
                        maxLength="10"
                        className="w-full px-4 py-2 bg-slate-50/50 border border-slate-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>

                {error && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="text-red-500 text-sm bg-red-50 p-3 rounded-lg"
                  >
                    {error}
                  </motion.div>
                )}

                {success && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="text-green-500 text-sm bg-green-50 p-3 rounded-lg"
                  >
                    Payment details saved successfully!
                  </motion.div>
                )}

                <div className="flex gap-3">
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    type="submit"
                    disabled={saving}
                    className="flex-1 bg-gradient-to-r from-blue-600 to-purple-600 text-white py-3 rounded-xl flex items-center justify-center space-x-2 text-lg font-medium hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-lg shadow-blue-500/20 hover:shadow-xl disabled:opacity-70"
                  >
                    {saving ? (
                      <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                    ) : (
                      <>
                        <Check size={20} />
                        <span>Save Changes</span>
                      </>
                    )}
                  </motion.button>

                  {hasExistingDetails && (
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      type="button"
                      onClick={() => {
                        setIsEditing(false);
                        fetchExistingDetails(); // Reset to saved data
                      }}
                      className="px-6 py-3 border border-slate-200 rounded-xl text-slate-600 hover:bg-slate-50 transition-colors"
                    >
                      <X size={20} />
                    </motion.button>
                  )}
                </div>
              </motion.form>
            ) : (
              <motion.div
                key="view-details"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="space-y-6"
              >
                {/* Bank Details View */}
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold text-slate-700 flex items-center gap-2">
                    <Bank size={20} className="text-blue-600" />
                    Bank Account Details
                  </h3>
                  <div className="bg-slate-50 rounded-xl p-4">
                    <DetailRow label="Account Holder Name" value={formData.accountHolderName} />
                    <DetailRow label="Account Number" value={formData.accountNumber} />
                    <DetailRow label="IFSC Code" value={formData.ifscCode} />
                    <DetailRow label="Bank Name" value={formData.bankName} />
                  </div>
                </div>

                {/* UPI and Mobile View */}
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold text-slate-700 flex items-center gap-2">
                    <IndianRupee size={20} className="text-blue-600" />
                    UPI & Mobile Details
                  </h3>
                  <div className="bg-slate-50 rounded-xl p-4">
                    <DetailRow label="UPI ID" value={formData.upiId} />
                    <DetailRow label="Mobile Number" value={formData.mobileNumber} />
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
};

export default PaymentDetailsPage;