import React from 'react';
import { motion } from 'framer-motion';
import { Users, TrendingUp } from 'lucide-react';

const SimpleHourlyStats = ({ products }) => {
  // Generate stats for each product
  const stats = products.map(product => ({
    ...product,
    buyers: Math.floor(Math.random() * (7 - 2) + 3), // 7-15 buyers in the last hour
  }));

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="bg-gradient-to-br from-cyan-50 to-white py-12"
    >
      <div className="container mx-auto px-4">
        {/* Section Title */}
        <motion.div
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          className="flex items-center justify-center space-x-2 mb-8"
        >
          <div className="h-2 w-2 bg-cyan-500 rounded-full animate-pulse" />
          <span className="text-cyan-600 font-medium">Purchase Activity of the Last One Hour</span>
        </motion.div>

        {/* Scrolling Activity */}
        <div className="flex overflow-x-hidden">
          <motion.div 
            className="flex space-x-4 animate-scroll"
            animate={{ x: [-1200, 1200] }}
            transition={{ 
              duration: 30,
              repeat: Infinity,
              ease: "linear"
            }}
          >
            {stats.concat(stats).map((stat, index) => (
              <motion.div
                key={`${stat.id}-${index}`}
                className="flex items-center space-x-3 bg-white px-4 py-3 rounded-xl shadow-sm border border-cyan-100"
                whileHover={{ y: -2, scale: 1.02 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                {/* Icon */}
                <div className="w-10 h-10 bg-cyan-50 rounded-full flex items-center justify-center">
                  <Users className="w-5 h-5 text-cyan-600" />
                </div>
                {/* Stats Details */}
                <div>
                  <p className="text-sm text-gray-600">{stat.buyers} buyers in the last hour</p>
                  <p className="text-xs text-cyan-600 font-medium truncate max-w-[150px]">
                    {stat.name}
                  </p>
                </div>
                {/* Purchase Status */}
                <div className="flex items-center text-green-600 text-xs font-medium">
                  <TrendingUp className="w-3 h-3 mr-1" />
                  <span>Purchased</span>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default SimpleHourlyStats;
