import React, { useState, useEffect } from 'react';
import { auth, firestore } from '../firebase';
import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword,
  sendPasswordResetEmail 
} from 'firebase/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  LogIn, 
  Mail, 
  Lock, 
  AlertCircle, 
  Loader2, 
  ArrowRight, 
  UserPlus, 
  Check, 
  X, 
  Users,
  Key
} from 'lucide-react';
import { 
  doc, 
  setDoc, 
  getDoc, 
  collection, 
  query, 
  where, 
  getDocs, 
  serverTimestamp 
} from 'firebase/firestore';
const KeyReset=Key;
const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Password validation states
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    number: false,
    special: false,
    uppercase: false,
    lowercase: false
  });

  // Previous useEffects remain the same...
  useEffect(() => {
    const refCode = searchParams.get('ref');
    if (refCode) {
      setReferralCode(refCode);
      setIsSigningUp(true);
    }
  }, [searchParams]);

  useEffect(() => {
    setPasswordCriteria({
      length: password.length >= 8,
      number: /\d/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password)
    });
  }, [password]);

  const isPasswordValid = Object.values(passwordCriteria).every(Boolean);

  // Existing verifyReferralCode function remains the same...
  const verifyReferralCode = async (code) => {
    if (!code) return true;
    try {
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef, where('referralCode', '==', code));
      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty;
    } catch (error) {
      console.error('Error verifying referral code:', error);
      return false;
    }
  };

  // New function to handle password reset
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    if (!email) {
      setError('Please enter your email address');
      return;
    }

    setIsLoading(true);
    setError('');
    setSuccess('');

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess('Password reset email sent! Please check your inbox.');
      setTimeout(() => {
        setIsForgotPassword(false);
        setSuccess('');
      }, 5000);
    } catch (error) {
      console.error('Password reset error:', error);
      setError('Unable to send reset email. Please verify your email address.');
    } finally {
      setIsLoading(false);
    }
  };

  // Modified handleSubmit to clear states when switching modes
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (isSigningUp && !isPasswordValid) {
      setError('Please meet all password requirements before creating an account.');
      return;
    }
    
    setIsLoading(true);
    
    try {
      if (isSigningUp) {
        if (referralCode) {
          const isValidReferral = await verifyReferralCode(referralCode);
          if (!isValidReferral) {
            setError('Invalid referral code');
            setIsLoading(false);
            return;
          }
        }

        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const newReferralCode = `${userCredential.user.uid.substr(0, 6).toUpperCase()}`;
        
        await setDoc(doc(firestore, 'users', userCredential.user.uid), {
          email: email,
          referralCode: newReferralCode,
          referredBy: referralCode || null,
          createdAt: serverTimestamp()
        });

      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
      navigate('/');
    } catch (error) {
      console.error('Auth error:', error);
      setError(
        isSigningUp 
          ? 'Unable to create account. Please check your details and try again.'
          : 'Invalid email or password. Please try again.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Animation variants remain the same...
  const containerVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.4,
        ease: "easeOut"
      }
    },
    exit: { 
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.3
      }
    }
  };

  const inputVariants = {
    focus: { 
      scale: 1.02,
      transition: { duration: 0.2 }
    }
  };

  const PasswordRequirement = ({ met, text }) => (
    <div className="flex items-center space-x-2">
      {met ? (
        <Check className="w-4 h-4 text-green-500" />
      ) : (
        <X className="w-4 h-4 text-gray-300" />
      )}
      <span className={`text-sm ${met ? 'text-green-500' : 'text-gray-500'}`}>
        {text}
      </span>
    </div>
  );

  const renderForm = () => {
    if (isForgotPassword) {
      return (
        <form onSubmit={handleForgotPassword} className="space-y-6">
          <div>
            <label 
              htmlFor="email" 
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Email Address
            </label>
            <motion.div 
              variants={inputVariants}
              whileFocus="focus"
              className="relative"
            >
              <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                required
                placeholder="Enter your email"
              />
            </motion.div>
          </div>

          <motion.button
            type="submit"
            disabled={isLoading}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white py-3 rounded-xl
              flex items-center justify-center space-x-2
              hover:from-blue-600 hover:to-blue-700 
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
              disabled:opacity-50 disabled:cursor-not-allowed
              transition-all duration-200"
          >
            {isLoading ? (
              <Loader2 className="w-5 h-5 animate-spin" />
            ) : (
              <>
                <KeyReset className="w-5 h-5 mr-2" />
                <span>Send Reset Link</span>
              </>
            )}
          </motion.button>
        </form>
      );
    }

    return (
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Existing form fields... */}
        <div>
          <label 
            htmlFor="email" 
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Email Address
          </label>
          <motion.div 
            variants={inputVariants}
            whileFocus="focus"
            className="relative"
          >
            <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
              required
              placeholder="Enter your email"
            />
          </motion.div>
        </div>

        <div>
          <label 
            htmlFor="password" 
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Password
          </label>
          <motion.div 
            variants={inputVariants}
            whileFocus="focus"
            className="relative"
          >
            <Lock className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
              required
              placeholder="Enter your password"
            />
          </motion.div>
        </div>

        {/* Referral code field */}
        {isSigningUp && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
          >
            <label 
              htmlFor="referralCode" 
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Referral Code (Optional)
            </label>
            <motion.div 
              variants={inputVariants}
              whileFocus="focus"
              className="relative"
            >
              <Users className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
              <input
                id="referralCode"
                type="text"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value.toUpperCase())}
                className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                placeholder="Enter referral code (optional)"
              />
            </motion.div>
          </motion.div>
        )}

        {/* Password requirements */}
        {isSigningUp && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="bg-gray-50 p-4 rounded-xl space-y-2"
          >
            <h3 className="text-sm font-medium text-gray-700 mb-3">Password Requirements:</h3>
            <PasswordRequirement met={passwordCriteria.length} text="At least 8 characters long" />
            <PasswordRequirement met={passwordCriteria.uppercase} text="Contains uppercase letter" />
            <PasswordRequirement met={passwordCriteria.lowercase} text="Contains lowercase letter" />
            <PasswordRequirement met={passwordCriteria.number} text="Contains number" />
            <PasswordRequirement met={passwordCriteria.special} text="Contains special character" />
          </motion.div>
        )}

        <motion.button
          type="submit"
          disabled={isLoading || (isSigningUp && !isPasswordValid)}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white py-3 rounded-xl
            flex items-center justify-center space-x-2
            hover:from-blue-600 hover:to-blue-700 
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
            disabled:opacity-50 disabled:cursor-not-allowed
            transition-all duration-200"
        >
          {isLoading ? (
            <Loader2 className="w-5 h-5 animate-spin" />
          ) : (
            <>
              <span>{isSigningUp ? 'Create Account' : 'Sign In'}</span>
              <ArrowRight className="w-5 h-5" />
            </>
          )}
        </motion.button>

        {/* Forgot Password Link - Only show on login form */}
        {!isSigningUp && (
          <motion.button
            type="button"
            onClick={() => {
              setIsForgotPassword(true);
              setError('');
              setSuccess('');
            }}
            className="w-full text-center text-sm text-gray-600 hover:text-blue-600 transition-colors mt-4"
          >
            Forgot your password?
          </motion.button>
        )}
      </form>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50 flex items-center justify-center p-4">
      <motion.div 
        variants={containerVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        className="w-full max-w-md"
      >
        <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
          <div className="p-8">
            <div className="text-center mb-8">
              <motion.div
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="inline-block p-3 rounded-full bg-blue-50 mb-4"
              >
                {isForgotPassword ? (
                  <KeyReset className="w-8 h-8 text-blue-600" />
                ) : isSigningUp ? (
                  <UserPlus className="w-8 h-8 text-blue-600" />
                ) : (
                  <LogIn className="w-8 h-8 text-blue-600" />
                )}
              </motion.div>
              <h1 className="text-2xl font-bold text-gray-900 mb-2">
                {isForgotPassword 
                  ? 'Reset Password' 
                  : isSigningUp 
                    ? 'Create Account' 
                    : 'Welcome Back'}
              </h1>
              <p className="text-gray-600">
                {isForgotPassword
                  ? 'Enter your email to receive a reset link'
                  : isSigningUp 
                    ? 'Start your investment journey today'
                    : 'Log in to manage your investments'}
              </p>
            </div>

            <AnimatePresence mode="wait">
              {error && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 10 }}
                  className="flex items-center gap-2 bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-xl mb-6"
                >
                  <AlertCircle className="w-5 h-5 flex-shrink-0" />
                  <p className="text-sm">{error}</p>
                </motion.div>
              )}

              {success && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 10 }}
                  className="flex items-center gap-2 bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded-xl mb-6"
                >
                  <Check className="w-5 h-5 flex-shrink-0" />
                  <p className="text-sm">{success}</p>
                </motion.div>
              )}
            </AnimatePresence>

            {renderForm()}
          </div>

          <div className="px-8 py-6 bg-gray-50 border-t border-gray-100">
            {isForgotPassword ? (
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => {
                  setIsForgotPassword(false);
                  setError('');
                  setSuccess('');
                }}
                className="w-full text-center text-sm text-gray-600 hover:text-blue-600 transition-colors"
              >
                Back to Sign In
              </motion.button>
            ) : (
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => {
                  setIsSigningUp(!isSigningUp);
                  setError('');
                  setPassword('');
                  if (!isSigningUp) {
                    const refCode = searchParams.get('ref');
                    if (refCode) setReferralCode(refCode);
                  }
                }}
                className="w-full text-center text-sm text-gray-600 hover:text-blue-600 transition-colors"
              >
                {isSigningUp 
                  ? 'Already have an account? Sign In' 
                  : 'Don\'t have an account? Sign Up'}
              </motion.button>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default LoginPage;

// import React, { useState, useEffect } from 'react';
// import { auth } from '../firebase';
// import { 
//   signInWithEmailAndPassword, 
//   createUserWithEmailAndPassword,
//   signInWithPopup,
//   GoogleAuthProvider,
//   PhoneAuthProvider,
//   signInWithPhoneNumber,
//   RecaptchaVerifier
// } from 'firebase/auth';
// import { useNavigate } from 'react-router-dom';
// import { motion, AnimatePresence } from 'framer-motion';
// import { 
//   LogIn, 
//   Mail, 
//   Lock, 
//   AlertCircle, 
//   Loader2, 
//   ArrowRight, 
//   UserPlus, 
//   Check, 
//   X,
//   Phone,
//   Globe
// } from 'lucide-react';

// const LoginPage = () => {
//   const [authMethod, setAuthMethod] = useState('email'); // 'email' | 'phone' | 'google'
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [verificationCode, setVerificationCode] = useState('');
//   const [verificationId, setVerificationId] = useState('');
//   const [showVerification, setShowVerification] = useState(false);
//   const [isSigningUp, setIsSigningUp] = useState(false);
//   const [error, setError] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const navigate = useNavigate();

//   // Password validation states
//   const [passwordCriteria, setPasswordCriteria] = useState({
//     length: false,
//     number: false,
//     special: false,
//     uppercase: false,
//     lowercase: false
//   });

//   useEffect(() => {
//     // Set up reCAPTCHA verifier
//     if (!window.recaptchaVerifier) {
//       window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
//         size: 'invisible'
//       });
//     }
//   }, []);

//   // Check password strength
//   useEffect(() => {
//     setPasswordCriteria({
//       length: password.length >= 8,
//       number: /\d/.test(password),
//       special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
//       uppercase: /[A-Z]/.test(password),
//       lowercase: /[a-z]/.test(password)
//     });
//   }, [password]);

//   const isPasswordValid = Object.values(passwordCriteria).every(Boolean);

//   const handleGoogleSignIn = async () => {
//     setIsLoading(true);
//     try {
//       const provider = new GoogleAuthProvider();
//       await signInWithPopup(auth, provider);
//       navigate('/');
//     } catch (error) {
//       console.error('Google auth error:', error);
//       setError('Failed to sign in with Google. Please try again.');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handlePhoneNumberSubmit = async () => {
//     setIsLoading(true);
//     try {
//       const phoneProvider = new PhoneAuthProvider(auth);
//       const verificationId = await signInWithPhoneNumber(
//         auth,
//         phoneNumber,
//         window.recaptchaVerifier
//       );
//       setVerificationId(verificationId);
//       setShowVerification(true);
//       setError('');
//     } catch (error) {
//       console.error('Phone auth error:', error);
//       setError('Failed to send verification code. Please check your phone number.');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleVerificationCodeSubmit = async () => {
//     setIsLoading(true);
//     try {
//       const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
//       await auth.signInWithCredential(credential);
//       navigate('/');
//     } catch (error) {
//       console.error('Verification error:', error);
//       setError('Invalid verification code. Please try again.');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleEmailSubmit = async (e) => {
//     e.preventDefault();
//     setError('');
    
//     if (isSigningUp && !isPasswordValid) {
//       setError('Please meet all password requirements.');
//       return;
//     }
    
//     setIsLoading(true);
    
//     try {
//       if (isSigningUp) {
//         await createUserWithEmailAndPassword(auth, email, password);
//       } else {
//         await signInWithEmailAndPassword(auth, email, password);
//       }
//       navigate('/');
//     } catch (error) {
//       console.error('Auth error:', error);
//       setError(
//         isSigningUp 
//           ? 'Unable to create account. Please try again.'
//           : 'Invalid email or password.'
//       );
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const AuthMethodSelector = () => (
//     <div className="flex space-x-2 mb-6">
//       {['email', 'phone', 'google'].map((method) => (
//         <motion.button
//           key={method}
//           whileHover={{ scale: 1.05 }}
//           whileTap={{ scale: 0.95 }}
//           onClick={() => {
//             setAuthMethod(method);
//             setError('');
//           }}
//           className={`flex-1 py-2 px-4 rounded-xl text-sm font-medium transition-colors
//             ${authMethod === method 
//               ? 'bg-blue-500 text-white' 
//               : 'bg-gray-100 text-gray-600 hover:bg-gray-200'}`}
//         >
//           {method.charAt(0).toUpperCase() + method.slice(1)}
//         </motion.button>
//       ))}
//     </div>
//   );

//   const renderAuthMethod = () => {
//     switch (authMethod) {
//       case 'phone':
//         return (
//           <div className="space-y-6">
//             {!showVerification ? (
//               <div>
//                 <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-2">
//                   Phone Number
//                 </label>
//                 <motion.div variants={inputVariants} whileFocus="focus" className="relative">
//                   <Phone className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
//                   <input
//                     id="phone"
//                     type="tel"
//                     value={phoneNumber}
//                     onChange={(e) => setPhoneNumber(e.target.value)}
//                     className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                     placeholder="+1 (234) 567-8900"
//                     required
//                   />
//                 </motion.div>
//                 <div id="recaptcha-container" />
//                 <motion.button
//                   onClick={handlePhoneNumberSubmit}
//                   disabled={isLoading || !phoneNumber}
//                   className="mt-4 w-full bg-blue-500 text-white py-3 rounded-xl"
//                 >
//                   {isLoading ? <Loader2 className="w-5 h-5 animate-spin mx-auto" /> : 'Send Code'}
//                 </motion.button>
//               </div>
//             ) : (
//               <div>
//                 <label htmlFor="code" className="block text-sm font-medium text-gray-700 mb-2">
//                   Verification Code
//                 </label>
//                 <motion.div variants={inputVariants} whileFocus="focus" className="relative">
//                   <Lock className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
//                   <input
//                     id="code"
//                     type="text"
//                     value={verificationCode}
//                     onChange={(e) => setVerificationCode(e.target.value)}
//                     className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                     placeholder="Enter 6-digit code"
//                     required
//                   />
//                 </motion.div>
//                 <motion.button
//                   onClick={handleVerificationCodeSubmit}
//                   disabled={isLoading || verificationCode.length !== 6}
//                   className="mt-4 w-full bg-blue-500 text-white py-3 rounded-xl"
//                 >
//                   {isLoading ? <Loader2 className="w-5 h-5 animate-spin mx-auto" /> : 'Verify Code'}
//                 </motion.button>
//               </div>
//             )}
//           </div>
//         );

//       case 'google':
//         return (
//           <motion.button
//             onClick={handleGoogleSignIn}
//             disabled={isLoading}
//             className="w-full bg-white border border-gray-200 text-gray-700 py-3 rounded-xl
//               flex items-center justify-center space-x-2 hover:bg-gray-50 transition-colors"
//           >
//             {isLoading ? (
//               <Loader2 className="w-5 h-5 animate-spin" />
//             ) : (
//               <>
//                 <Globe className="w-5 h-5 text-blue-500" />
//                 <span>Continue with Google</span>
//               </>
//             )}
//           </motion.button>
//         );

//       default: // email
//         return (
//           <form onSubmit={handleEmailSubmit} className="space-y-6">
//             {/* Email input */}
//             <div>
//               <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
//                 Email Address
//               </label>
//               <motion.div variants={inputVariants} whileFocus="focus" className="relative">
//                 <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
//                 <input
//                   id="email"
//                   type="email"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                   required
//                   placeholder="Enter your email"
//                 />
//               </motion.div>
//             </div>

//             {/* Password input */}
//             <div>
//               <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
//                 Password
//               </label>
//               <motion.div variants={inputVariants} whileFocus="focus" className="relative">
//                 <Lock className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
//                 <input
//                   id="password"
//                   type="password"
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                   className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                   required
//                   placeholder="Enter your password"
//                 />
//               </motion.div>
//             </div>

//             {/* Password requirements for signup */}
//             {isSigningUp && (
//               <motion.div
//                 initial={{ opacity: 0, height: 0 }}
//                 animate={{ opacity: 1, height: 'auto' }}
//                 exit={{ opacity: 0, height: 0 }}
//                 className="bg-gray-50 p-4 rounded-xl space-y-2"
//               >
//                 <h3 className="text-sm font-medium text-gray-700 mb-3">Password Requirements:</h3>
//                 <PasswordRequirement met={passwordCriteria.length} text="At least 8 characters" />
//                 <PasswordRequirement met={passwordCriteria.uppercase} text="Uppercase letter" />
//                 <PasswordRequirement met={passwordCriteria.lowercase} text="Lowercase letter" />
//                 <PasswordRequirement met={passwordCriteria.number} text="Number" />
//                 <PasswordRequirement met={passwordCriteria.special} text="Special character" />
//               </motion.div>
//             )}

//             {/* Submit button */}
//             <motion.button
//               type="submit"
//               disabled={isLoading || (isSigningUp && !isPasswordValid)}
//               whileHover={{ scale: 1.02 }}
//               whileTap={{ scale: 0.98 }}
//               className="w-full bg-blue-500 text-white py-3 rounded-xl flex items-center justify-center space-x-2
//                 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200"
//             >
//               {isLoading ? (
//                 <Loader2 className="w-5 h-5 animate-spin" />
//               ) : (
//                 <>
//                   <span>{isSigningUp ? 'Create Account' : 'Sign In'}</span>
//                   <ArrowRight className="w-5 h-5" />
//                 </>
//               )}
//             </motion.button>
//           </form>
//         );
//     }
//   };

//   const containerVariants = {
//     initial: { opacity: 0, y: 20 },
//     animate: { opacity: 1, y: 0, transition: { duration: 0.4 } },
//     exit: { opacity: 0, y: -20, transition: { duration: 0.3 } }
//   };

//   const inputVariants = {
//     focus: { scale: 1.02, transition: { duration: 0.2 } }
//   };

//   const PasswordRequirement = ({ met, text }) => (
//     <div className="flex items-center space-x-2">
//       {met ? (
//         <Check className="w-4 h-4 text-green-500" />
//       ) : (
//         <X className="w-4 h-4 text-gray-300" />
//       )}
//       <span className={`text-sm ${met ? 'text-green-500' : 'text-gray-500'}`}>
//         {text}
//       </span>
//     </div>
//   );

//   return (
//     <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50 flex items-center justify-center p-4">
//       <motion.div 
//         variants={containerVariants}
//         initial="initial"
//         animate="animate"
//         exit="exit"
//         className="w-full max-w-md"
//       >
//         <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
//           <div className="p-8">
//             <div className="text-center mb-8">
//               <motion.div
//                 initial={{ scale: 0.5, opacity: 0 }}
//                 animate={{ scale: 1, opacity: 1 }}
//                 transition={{ duration: 0.5 }}
//                 className="inline-block p-3 rounded-full bg-blue-50 mb-4"
//               >
//                 {isSigningUp ? (
//                   <UserPlus className="w-8 h-8 text-blue-600" />
//                 ) : (
//                   <LogIn className="w-8 h-8 text-blue-600" />
//                 )}
//               </motion.div>
//               <h1 className="text-2xl font-bold text-gray-900 mb-2">
//                 {isSigningUp ? 'Create Account' : 'Welcome Back'}
//               </h1>
//               <p className="text-gray-600">
//                 {isSigningUp 
//                   ? 'Start your journey today'
//                   : 'Sign in to continue'}
//               </p>
//             </div>

//             <AnimatePresence mode="wait">
//               {error && (
//                 <motion.div
//                   initial={{ opacity: 0, y: -10 }}
//                   animate={{ opacity: 1, y: 0 }}
//                   exit={{ opacity: 0, y: 10 }}
//                   className="flex items-center gap-2 bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-xl mb-6"
//                 >
//                   <AlertCircle className="w-5 h-5 flex-shrink-0" />
//                   <p className="text-sm">{error}</p>
//                 </motion.div>
//               )}
//             </AnimatePresence>

//             {/* Auth Method Selector */}
//             <AuthMethodSelector />

//             {/* Render selected auth method form */}
//             {renderAuthMethod()}

//             {/* Divider with "or" text */}
//             {authMethod !== 'google' && (
//               <div className="relative my-8">
//                 <div className="absolute inset-0 flex items-center">
//                   <div className="w-full border-t border-gray-200" />
//                 </div>
//                 <div className="relative flex justify-center text-sm">
//                   <span className="px-4 bg-white text-gray-500">or</span>
//                 </div>
//               </div>
//             )}

//             {/* Sign in with Google button (shown when not on Google auth method) */}
//             {authMethod !== 'google' && (
//               <motion.button
//                 onClick={handleGoogleSignIn}
//                 whileHover={{ scale: 1.02 }}
//                 whileTap={{ scale: 0.98 }}
//                 className="w-full bg-white border border-gray-200 text-gray-700 py-3 rounded-xl
//                   flex items-center justify-center space-x-2 hover:bg-gray-50 
//                   transition-all duration-200 mb-6"
//               >
//                 <Globe className="w-5 h-5 text-blue-500" />
//                 <span>Continue with Google</span>
//               </motion.button>
//             )}
//           </div>

//           {/* Footer section */}
//           <div className="px-8 py-6 bg-gray-50 border-t border-gray-100">
//             <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
//               <motion.button
//                 whileHover={{ scale: 1.02 }}
//                 whileTap={{ scale: 0.98 }}
//                 onClick={() => {
//                   setIsSigningUp(!isSigningUp);
//                   setError('');
//                   setPassword('');
//                   setShowVerification(false);
//                 }}
//                 className="text-sm text-gray-600 hover:text-blue-600 transition-colors"
//               >
//                 {isSigningUp 
//                   ? 'Already have an account? Sign In' 
//                   : 'Don\'t have an account? Sign Up'}
//               </motion.button>

//               {/* Password reset link - only show for email auth */}
//               {authMethod === 'email' && !isSigningUp && (
//                 <motion.button
//                   whileHover={{ scale: 1.02 }}
//                   whileTap={{ scale: 0.98 }}
//                   onClick={() => navigate('/reset-password')}
//                   className="text-sm text-blue-600 hover:text-blue-700 transition-colors"
//                 >
//                   Forgot Password?
//                 </motion.button>
//               )}
//             </div>
//           </div>
//         </div>
//       </motion.div>
//     </div>
//   );
// };

// export default LoginPage;