import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ShoppingBag, MapPin, Clock, ChevronUp } from 'lucide-react';

const indianNames = [
    "Aarav Patel", "Advait Sharma", "Arjun Kumar", "Diya Singh", "Ishaan Gupta",
    "Kavya Reddy", "Krishna Iyer", "Mira Malhotra", "Neha Verma", "Om Choudhury",
    "Priya Nair", "Riya Kapoor", "Rohan Mehta", "Sai Desai", "Tanvi Joshi",
    "Ved Saxena", "Zara Khan", "Advika Das", "Aryan Shah", "Dia Mehra",
    "Aarav", "Abhinav", "Aditya", "Akhil", "Amulya", "Ananya", "Anil", "Arjun",
    "Arya", "Bhargav", "Bhavana", "Chandana", "Charan", "Charitha", "Chaitanya",
    "Deepak", "Divya", "Durga", "Gautham", "Geetha", "Girish", "Harika", "Harini",
    "Harsha", "Hemant", "Hemanth", "Hiranmayi", "Indira", "Jagadeesh", "Jayanth",
    "Jayasree", "Jeevitha", "Jyothi", "Kalyan", "Karthik", "Kavya", "Keerthi",
    "Kiran", "Lakshmi", "Lalitha", "Lavanya", "Lokesh", "Madhavi", "Mahesh",
    "Mounika", "Nagesh", "Naveen", "Niharika", "Nikhil", "Nithin", "Padma",
    "Pavani", "Pooja", "Prakash", "Pranavi", "Praneeth", "Prasanna", "Priya",
    "Priyanka", "Raghavendra", "Rajesh", "Rakesh", "Ramakrishna", "Ramya", "Ravi",
    "Renuka", "Rithika", "Rohit", "Rupa", "Sagar", "Sahithi", "Sai", "Sandhya",
    "Sanjay", "Santosh", "Saritha", "Satish", "Satya", "Seetha", "Sharath",
    "Shravya", "Shruthi", "Sindhu", "Siva", "Sneha", "Srinivas", "Sruthi", 
    "Subhash", "Sudha", "Suresh", "Sushma", "Swathi", "Teja", "Tejaswini", 
    "Uday", "Uma", "Usha", "Vamsi", "Varun", "Vasavi", "Venkatesh", "Vidya", 
    "Vijay", "Vinay", "Vinod", "Vishwa", "Vivek", "Yashwanth", "Yogesh", 
    "Abhigna", "Akhila", "Anusha", "Aparna", "Aravind", "Bhanu", "Chandrakanth", 
    "Chandu", "Dinesh", "Ganga", "Ganesh", "Gayathri", "Hemalatha", "Ishwarya", 
    "Jagadish", "Janaki", "Jayalakshmi", "Jyothirmayi", "Karthika", "Kaveri", 
    "Keerthana", "Krishnaveni", "Kusuma", "Lakshman", "Lalith", "Madhu", 
    "Mahendra", "Mahitha", "Manoj", "Meena", "Mohan", "Mouni", "Murali", 
    "Mythri", "Nandini", "Neha", "Nikhitha", "Nirmala", "Omkar", "Pallavi", 
    "Parvathi", "Phanindra", "Poorna", "Prabhakar", "Pradeep", "Pragya", 
    "Preethi", "Prithvi", "Purnima", "Radhika", "Rajitha", "Ramadevi", 
    "Ramesh", "Rekha", "Roshini", "Sahana", "Samhitha", "Sampath", "Sanjeev", 
    "Sarada", "Sarojini", "Shanmukha", "Shashi", "Sireesha", "Sivananda", 
    "Sreedevi", "Sriram", "Srivalli", "Subhashini", "Sudheer", "Sujatha", 
    "Sumathi", "Sunanda", "Suraj", "Suryanarayana", "Swarna", "Tanmay", 
    "Tarun", "Tulasi", "Uma Maheswari", "Vamshi", "Vanaja", "Varalakshmi", 
    "Varaprasad", "Vasantha", "Venkata", "Vishnu", "Vyshnavi", "Yamini", 
    "Aarathi", "Abhay", "Akanksha", "Amarnath", "Anil Kumar", "Arpita", 
    "Ashok", "Balu", "Bharathi", "Bhaskar", "Brahmaji", "Chandrika", 
    "Chiranjeevi", "Damodhar", "Durgesh", "Eswar", "Gopi", "Govind", "Guru", 
    "Hanumanth", "Harish", "Indu", "Jagannath", "Janardhan", "Jhansi", 
    "Kalyani", "Kamala", "Kamalakar", "Kanaka", "Kanthi", "Karuna", 
    "Karthikeyan", "Kavitha", "Kusum", "Laxmi", "Madhavi Latha", "Mallesh", 
    "Mallikarjun", "Mamatha", "Manasa", "Manohar", "Meenakshi", "Nagarjuna", 
    "Nagendra", "Nagmani", "Nalini", "Narayan", "Neelima", "Nihar", 
    "Niveditha", "Omprakash", "Pavithra", "Poornachandra", "Prakasham", 
    "Pranitha", "Pratap", "Prema", "Priyanjali", "Radha Krishna", "Rajani", 
    "Rajasekhar", "Rajender", "Raja Sekhar", "Ramani", "Ram Mohan", "Ramu", 
    "Ratna", "Roja", "Sagarika", "Sahasra", "Sameera", "Sampada", 
    "Sangeetha", "Saritha Reddy", "Satheesh", "Shanthi", "Sharvani", 
    "Shivani", "Shravani", "Shyamala", "Sindhura", "Sireesha Reddy", 
    "Sivasankar", "Srinivasa Reddy", "Sudhakar", "Suhasini", "Sunil", 
    "Swapna", "Taraka", "Teja Reddy", "Tejasri", "Ujwala", "Uma Shankar", 
    "Usha Rani", "Vagdevi", "Vaishnavi", "Vallabha", "Vani", "Venu", 
    "Venugopal", "Vidhya", "Vijaya Lakshmi", "Vinitha", "Vivekananda", 
    "Yamuna", "Yashaswini", "Yaswanth"
  ];
  

  const cities = [
    "Mumbai", "Delhi", "Bangalore", "Chennai", "Kolkata", 
    "Hyderabad", "Pune", "Ahmedabad", "Jaipur", "Lucknow",
    // Andhra Pradesh cities
    "Visakhapatnam", "Vijayawada", "Guntur", "Nellore", "Tirupati", 
    "Kakinada", "Rajahmundry", "Anantapur", "Kadapa", "Chittoor", 
    "Ongole", "Eluru", "Machilipatnam", "Vizianagaram", "Srikakulam", 
    "Amaravati", "Proddatur", "Nandyal", "Adoni", "Markapur",
  
    // Telangana cities
    "Hyderabad", "Warangal", "Nizamabad", "Khammam", "Karimnagar", 
    "Ramagundam", "Mahabubnagar", "Mancherial", "Adilabad", "Siddipet", 
    "Suryapet", "Jangaon", "Miryalaguda", "Bhadrachalam", "Kamareddy", 
    "Zaheerabad", "Vikarabad", "Bhongir", "Nirmal", "Wanaparthy"
  ];
  

const LivePurchaseNotifications = ({ products }) => {
  const [notifications, setNotifications] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const generatePurchase = () => ({
      id: Date.now(),
      name: indianNames[Math.floor(Math.random() * indianNames.length)],
      city: cities[Math.floor(Math.random() * cities.length)],
      product: products[Math.floor(Math.random() * products.length)],
      timeAgo: "Just now"
    });

    // Initialize with one notification
    setNotifications([generatePurchase()]);

    const interval = setInterval(() => {
      setNotifications(prev => {
        const newNotification = generatePurchase();
        const updatedNotifications = [newNotification, ...prev];
        return updatedNotifications.slice(0, 5); // Keep last 5 notifications
      });
      
      // Auto-cycle through notifications
      setCurrentIndex(prev => (prev + 1) % 5);
    }, 5000);

    return () => clearInterval(interval);
  }, [products]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="fixed z-50 pointer-events-none">
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className={`fixed ${isExpanded ? 'bottom-4' : 'bottom-4'} left-4 right-4 md:left-6 md:right-auto md:w-80`}
      >
        <motion.div
          className="bg-white rounded-2xl shadow-lg pointer-events-auto cursor-pointer
                     border border-cyan-100 overflow-hidden relative"
          whileHover={{ y: -2 }}
          onClick={toggleExpand}
        >
          {/* Main Notification Content */}
          <AnimatePresence mode="wait">
            <motion.div
              key={notifications[currentIndex]?.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ type: "spring", stiffness: 300, damping: 25 }}
              className="p-4 bg-gradient-to-r from-white to-cyan-50"
            >
              <div className="flex items-center gap-3">
                <div className="relative">
                  <div className="bg-gradient-to-br from-cyan-100 to-blue-100 p-2.5 rounded-full shadow-inner">
                    <ShoppingBag className="w-5 h-5 text-cyan-600" />
                  </div>
                  <motion.div
                    className="absolute inset-0 rounded-full border-2 border-cyan-200"
                    animate={{ scale: [1, 1.4, 1] }}
                    transition={{ duration: 2, repeat: Infinity }}
                  />
                </div>

                <div className="flex-1 min-w-0">
                  <div className="flex items-center gap-2 mb-1">
                    <h3 className="font-semibold text-gray-800 truncate text-sm">
                      {notifications[currentIndex]?.name}
                    </h3>
                  </div>
                  
                  <p className="text-sm font-medium text-cyan-600 truncate">
                    {notifications[currentIndex]?.product.name}
                  </p>

                  <div className="flex items-center gap-3 mt-1">
                    <div className="flex items-center text-xs text-gray-500">
                      <MapPin className="w-3 h-3 mr-1" />
                      {notifications[currentIndex]?.city}
                    </div>
                    <div className="flex items-center text-xs text-gray-500">
                      <Clock className="w-3 h-3 mr-1" />
                      {notifications[currentIndex]?.timeAgo}
                    </div>
                  </div>
                </div>

                <motion.div
                  animate={{ rotate: isExpanded ? 180 : 0 }}
                  className="text-gray-400"
                >
                  <ChevronUp className="w-5 h-5" />
                </motion.div>
              </div>
            </motion.div>
          </AnimatePresence>

          {/* Progress Bar */}
          <motion.div 
            className="h-1 bg-gradient-to-r from-cyan-500 to-blue-500"
            initial={{ scaleX: 1 }}
            animate={{ scaleX: 0 }}
            transition={{ duration: 5, ease: "linear" }}
          />

          {/* Notification Counter */}
          <div className="absolute top-2 right-2">
            <div className="flex gap-1">
              {[...Array(Math.min(5, notifications.length))].map((_, idx) => (
                <motion.div
                  key={idx}
                  className="w-1 h-1 rounded-full"
                  style={{
                    backgroundColor: idx === currentIndex ? '#0891b2' : '#e2e8f0'
                  }}
                  animate={{
                    scale: idx === currentIndex ? [1, 1.5, 1] : 1,
                  }}
                  transition={{
                    duration: 1,
                    repeat: idx === currentIndex ? Infinity : 0,
                  }}
                />
              ))}
            </div>
          </div>
        </motion.div>

        {/* Expanded View */}
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              className="mt-2 space-y-2 overflow-hidden"
            >
              {notifications.slice(1).map((notification, idx) => (
                <motion.div
                  key={notification.id}
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 0.7 }}
                  transition={{ delay: idx * 0.1 }}
                  className="bg-white/90 backdrop-blur rounded-xl p-3 border border-cyan-50 shadow-sm"
                >
                  <div className="flex items-center gap-3">
                    <div className="bg-cyan-50 p-2 rounded-full">
                      <ShoppingBag className="w-4 h-4 text-cyan-500" />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm text-gray-600 truncate">
                        {notification.name}
                      </p>
                      <p className="text-xs text-cyan-600 truncate">
                        {notification.product.name}
                      </p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default LivePurchaseNotifications;