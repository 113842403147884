import React, { useState, useEffect } from 'react';
import { Bell, X, Check } from 'lucide-react';
import { collection, query, where, onSnapshot, updateDoc, doc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { motion, AnimatePresence } from 'framer-motion';

const NotificationBell = ({ userId }) => {
  const [notifications, setNotifications] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (!userId) return;
    const notificationsRef = collection(firestore, 'messages');
    const userNotificationsQuery = query(notificationsRef, where('userId', '==', userId));
    return onSnapshot(userNotificationsQuery, (snapshot) => {
      const newNotifications = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate()
      }));
      setNotifications(newNotifications);
      setUnreadCount(newNotifications.filter(n => !n.read).length);
    });
  }, [userId]);

  const markAsRead = async (notificationId) => {
    try {
      await updateDoc(doc(firestore, 'messages', notificationId), { read: true });
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <div className=" relative inline-flex">
      <motion.button
        onClick={() => setIsOpen(!isOpen)}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="relative inline-flex items-center py-2 px-3 text-gray-700 hover:text-blue-600 transition-colors duration-200"
      >
        <Bell className="h-5 w-5" />
        <AnimatePresence>
          {unreadCount > 0 && (
            <motion.span
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
              className="absolute -top-1 -right-1 h-4 w-4 bg-red-500 rounded-full flex items-center justify-center text-xs text-white font-bold"
            >
              {unreadCount}
            </motion.span>
          )}
        </AnimatePresence>
      </motion.button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 10, scale: 0.95 }}
            animate={{ 
              opacity: 1, 
              y: 0, 
              scale: 1,
              transition: {
                type: "spring",
                stiffness: 350,
                damping: 25
              }
            }}
            exit={{ 
              opacity: 0,
              y: 10,
              scale: 0.95,
              transition: { duration: 0.2 }
            }}
            className="absolute right-0 mt-8 w-72 bg-white rounded-lg shadow-lg border border-gray-100 overflow-hidden z-50"
          >
            <div className="p-3 bg-gradient-to-r from-blue-50 to-blue-100 flex justify-between items-center">
              <h3 className="font-medium text-gray-700">Notifications</h3>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setIsOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-4 h-4" />
              </motion.button>
            </div>

            <div className=" max-h-[calc(100vh-200px)] overflow-y-auto">
              <AnimatePresence initial={false}>
                {notifications.map((notification, index) => (
                  <motion.div
                    key={notification.id}
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                    transition={{ delay: index * 0.05 }}
                    className={`p-3  border-b border-gray-100 hover:bg-gray-50 
                      ${!notification.read ? 'bg-blue-50/50' : ''}`}
                  >
                    <div className="flex justify-between gap-2">
                      <p className="text-sm text-gray-700">{notification.message}</p>
                      {!notification.read && (
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          onClick={() => markAsRead(notification.id)}
                          className="text-blue-500 hover:text-blue-600"
                        >
                          <Check className="w-4 h-4" />
                        </motion.button>
                      )}
                    </div>
                    <span className="text-xs text-gray-500 mt-1 block">
                      {notification.timestamp?.toLocaleString()}
                    </span>
                  </motion.div>
                ))}
              </AnimatePresence>
              {notifications.length === 0 && (
                <div className="p-4 text-center text-gray-500">
                  No notifications
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NotificationBell;