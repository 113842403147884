// ReferralBonus.js
import React, { useState, useEffect } from 'react';
import { Share2,MousePointer,LucideMousePointerClick,Info, Lightbulb, Users, Copy, CheckCircle, X, Mail, ArrowRight, Loader2 } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  doc, 
  getDoc,
  setDoc 
} from 'firebase/firestore';
import { auth, firestore } from '../firebase';
const showHint= Lightbulb;

const ReferralBonus = ({ onReferralAmountChange }) => {
  const [referralCode, setReferralCode] = useState('');
  const [referralCount, setReferralCount] = useState(0);
  const [referralAmount, setReferralAmount] = useState(0);
  const [copied, setCopied] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [referralDetails, setReferralDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const REFERRAL_BONUS = 100;

  useEffect(() => {
    fetchReferralData();
  }, []);

  const fetchReferralData = async () => {
    setLoading(true);
    try {
      // Get user's referral code
      const userDoc = await getDoc(doc(firestore, 'users', auth.currentUser.uid));
      let userReferralCode = userDoc.data()?.referralCode;
      
      if (!userReferralCode) {
        userReferralCode = generateReferralCode();
        await setDoc(doc(firestore, 'users', auth.currentUser.uid), {
          ...userDoc.data(),
          referralCode: userReferralCode
        }, { merge: true });
      }
      
      setReferralCode(userReferralCode);

      // Fetch referral details
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef, where('referredBy', '==', userReferralCode));
      const querySnapshot = await getDocs(q);
      
      let successfulReferrals = 0;
      let referralsList = [];
      
      // Check each referred user
      for (const userDoc of querySnapshot.docs) {
        const userData = userDoc.data();
        const ordersRef = collection(firestore, 'orders');
        const orderQuery = query(
          ordersRef,
          where('userId', '==', userDoc.id),
          where('status', '==', 'confirmed')
        );
        const orderSnapshot = await getDocs(orderQuery);
        
        const hasPurchase = !orderSnapshot.empty;
        if (hasPurchase) {
          successfulReferrals++;
        }
        
        referralsList.push({
          email: userData.email,
          joinDate: userData.createdAt?.toDate() || new Date(),
          hasPurchase,
          firstPurchaseDate: hasPurchase ? orderSnapshot.docs[0].data().createdAt?.toDate() : null
        });
      }
      
      setReferralCount(successfulReferrals);
      setReferralDetails(referralsList.sort((a, b) => b.joinDate - a.joinDate));
      const totalAmount = successfulReferrals * REFERRAL_BONUS;
      setReferralAmount(totalAmount);
      onReferralAmountChange(totalAmount);
      
    } catch (error) {
      console.error('Error fetching referral data:', error);
    } finally {
      setLoading(false);
    }
  };

  const generateReferralCode = () => {
    const userId = auth.currentUser.uid;
    return `${userId.substr(0, 6).toUpperCase()}`;
  };

  const copyReferralLink = async () => {
    const referralLink = `${window.location.origin}/login?ref=${referralCode}`;
    try {
      await navigator.clipboard.writeText(referralLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const shareReferralLink = async () => {
    const referralLink = `${window.location.origin}/signup?ref=${referralCode}`;
    try {
      await navigator.share({
        title: 'Join using my referral code',
        text: 'Sign up using my referral code and get started!',
        url: referralLink,
      });
    } catch (err) {
      console.error('Share failed:', err);
    }
  };

  const ReferralDetailsModal = () => (
    <AnimatePresence>
      {showModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
          onClick={() => setShowModal(false)}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            onClick={e => e.stopPropagation()}
            className="bg-white rounded-2xl w-full max-w-lg max-h-[80vh] overflow-hidden shadow-xl"
          >
            {/* Modal Header */}
            <div className="p-4 sm:p-6 border-b border-gray-100 flex justify-between items-center">
              <div className="flex items-center space-x-3">
                <Users className="text-blue-600" size={24} />
                <h3 className="text-xl font-bold text-gray-900">Referral Details</h3>
              </div>
              <button
                onClick={() => setShowModal(false)}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <X size={20} className="text-gray-500" />
              </button>
            </div>

            {/* Modal Content */}
            <div className="p-4 sm:p-6 overflow-y-auto max-h-[60vh]">
              {loading ? (
                <div className="flex items-center justify-center py-8">
                  <Loader2 className="w-8 h-8 text-blue-600 animate-spin" />
                </div>
              ) : referralDetails.length > 0 ? (
                <div className="space-y-4">
                  {referralDetails.map((referral, index) => (
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      key={referral.email}
                      className="bg-gray-50 rounded-xl p-4 space-y-2"
                    >
                      <div className="flex items-start justify-between">
                        <div className="flex items-center space-x-3">
                          <Mail className="text-gray-400" size={16} />
                          <span className="text-sm font-medium text-gray-700">
                            {referral.email}
                          </span>
                        </div>
                        <span className={`text-xs px-2 py-1 rounded-full ${
                          referral.hasPurchase 
                            ? 'bg-green-100 text-green-700' 
                            : 'bg-gray-100 text-gray-600'
                        }`}>
                          {referral.hasPurchase ? 'Active' : 'Pending'}
                        </span>
                      </div>
                      <div className="text-xs text-gray-500">
                        Joined: {referral.joinDate.toLocaleDateString()}
                        {referral.hasPurchase && referral.firstPurchaseDate && (
                          <div>
                            First Purchase: {referral.firstPurchaseDate.toLocaleDateString()}
                          </div>
                        )}
                      </div>
                    </motion.div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-8">
                  <Users className="mx-auto text-gray-400 mb-4" size={48} />
                  <p className="text-gray-600">No referrals yet</p>
                </div>
              )}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white/90 backdrop-blur-sm rounded-2xl p-4 sm:p-6 shadow-xl border border-white/20"
      >
        <div className="flex items-center space-x-3 mb-6">
          <Users className="text-blue-600" size={20} />
          <h3 className="text-xl font-bold text-slate-800">Referral Bonus</h3>
        </div>

        <div className="space-y-6">
          {/* Referral Stats */}
          <div className="grid grid-cols-2 gap-4">
            <motion.button
              onClick={() => setShowModal(true)}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="bg-blue-50 rounded-xl p-4 text-left group cursor-pointer"
            >
              <p className="text-sm text-slate-600 mb-1">Total Referrals </p>
              <div className="flex items-center justify-between">
                <p className="text-2xl font-bold text-blue-600">{referralCount}</p>
                
                <ArrowRight className="text-blue-400 opacity-50 group-hover:opacity-100 transition-opacity" size={16} />
              </div>
            </motion.button>
            <div className="bg-green-50 rounded-xl p-4">
              <p className="text-sm text-slate-600 mb-1">Total Earnings</p>
              <p className="text-2xl font-bold text-green-600">₹{referralAmount}</p>
            </div>
          </div>

          {/* Referral Code Section */}
          <motion.div 
      whileHover={{ scale: 1.01 }}
      className="bg-slate-50 rounded-xl p-3 sm:p-4 w-full max-w-md mx-auto shadow-sm"
    >
      <p className="text-xs sm:text-sm text-slate-600 mb-2 font-medium">Your Referral Code</p>
      
      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
        <div className="relative flex-1">
          <input
            type="text"
            value={referralCode}
            readOnly
            className="w-full bg-white px-3 py-2.5 rounded-lg border border-slate-200 text-base sm:text-lg font-medium focus:ring-2 focus:ring-blue-100 focus:border-blue-300 transition-all"
          />
        </div>
        
        <div className="flex space-x-2 justify-end sm:justify-start">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={copyReferralLink}
            className="p-2.5 bg-blue-50 rounded-lg text-blue-600 hover:bg-blue-100 active:bg-blue-200 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-200 focus:ring-offset-2"
            aria-label={copied ? "Copied!" : "Copy referral code"}
          >
            {copied ? (
              <CheckCircle size={20} className="text-green-600" />
            ) : (
              <Copy size={20} />
            )}
          </motion.button>
          
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={shareReferralLink}
            className="p-2.5 bg-blue-50 rounded-lg text-blue-600 hover:bg-blue-100 active:bg-blue-200 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-200 focus:ring-offset-2"
            aria-label="Share referral code"
          >
            <Share2 size={20} />
          </motion.button>
        </div>
      </div>
    </motion.div>
          {/* Info Text */}
          <p className="text-sm text-slate-600 text-center">
            Earn ₹{REFERRAL_BONUS} for each friend who signs up and makes a purchase
          </p>
        </div>
      </motion.div>

      <ReferralDetailsModal />
    </>
  );
};

export default ReferralBonus;