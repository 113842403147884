import React, { useState, useEffect } from 'react';
import { collection, query, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import ProductPurchaseFlow from './ProductPurchaseFlow';
import LivePurchaseNotifications from './LivePurchaseNotifications';
import { motion, AnimatePresence } from 'framer-motion';
import HourlyProductStats from './HourlyProductStats';
import { 
  BookOpen,
  TrendingUp, 
  ShieldCheck, 
  GraduationCap,
  Clock,
  Lightbulb,
  Target,
  Brain,
  Star,ShoppingCart
} from 'lucide-react';

const ProductListingPage = () => {
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const q = query(collection(firestore, 'products'));
        const querySnapshot = await getDocs(q);
        const fetchedProducts = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })).sort((a, b) => b.rewardPercentage - a.rewardPercentage);
        
        setProducts(fetchedProducts);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleBuyNow = (product) => {
    setCurrentProduct(product);
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-cyan-50 to-blue-100">
        <motion.div 
          animate={{ 
            rotate: 360,
            scale: [1, 1.2, 1],
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "easeInOut"
          }}
          className="rounded-full h-24 w-24 border-4 border-cyan-500 flex items-center justify-center"
        >
          <Lightbulb className="w-12 h-12 text-cyan-600 animate-pulse" />
        </motion.div>
      </div>
    );
  }

  return (
    <div className="bg-slate-50 min-h-screen font-sans">
      {/* Hero Header */}
      <header className="bg-gradient-to-r from-cyan-600 to-blue-700 text-white">
        <div className="container mx-auto px-6 py-20 grid md:grid-cols-2 gap-12 items-center">
          <motion.div 
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, type: "spring" }}
          >
            <h1 className="text-6xl font-bold mb-6 leading-tight">
              Learn. Invest.
              <span className="block text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 to-yellow-500">
                Grow Together.
              </span>
            </h1>
            <p className="text-xl text-gray-100 mb-8 leading-relaxed">
              Master the art of investing through education. Join LearnAndGrab's community of smart investors who understand before they invest.
            </p>
            <motion.div 
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.6 }}
              className="space-x-4"
            >
              <a 
                href="#products" 
                className="inline-flex items-center px-8 py-4 bg-white text-cyan-700 
                           rounded-xl font-semibold hover:bg-cyan-50 transition-all 
                           shadow-lg hover:shadow-xl group"
              >
                Start Learning
                <BookOpen className="ml-2 group-hover:rotate-12 transition-transform" />
              </a>
            </motion.div>
          </motion.div>

          <motion.div 
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.7 }}
            className="hidden md:block"
          >
            <div className="bg-white/10 p-8 rounded-2xl backdrop-blur-xl border border-white/20">
              {[
                { 
                  icon: Brain, 
                  color: "text-yellow-300", 
                  title: "Smart Learning", 
                  subtitle: "Expert-Crafted Content" 
                },
                { 
                  icon: GraduationCap, 
                  color: "text-green-300", 
                  title: "Practical Skills", 
                  subtitle: "Learn by Doing" 
                },
                { 
                  icon: Target, 
                  color: "text-blue-300", 
                  title: "Goal-Oriented", 
                  subtitle: "Track Your Progress" 
                }
              ].map((feature, index) => (
                <motion.div 
                  key={feature.title}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="flex items-center space-x-4 mb-6 p-4 rounded-xl 
                             hover:bg-white/10 transition-colors cursor-pointer"
                >
                  <feature.icon className={`${feature.color} w-12 h-12`} />
                  <div>
                    <h3 className="font-bold text-lg text-white">{feature.title}</h3>
                    <p className="text-gray-200">{feature.subtitle}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </header>
      <HourlyProductStats products={products} />

      {/* Investment Courses Section */}
      <section id="products" className="container mx-auto px-6 py-20">
      
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-4 text-transparent 
                         bg-clip-text bg-gradient-to-r from-cyan-600 to-blue-600">
            Investment Learning Paths
          </h2>
          <p className="text-gray-600 text-lg max-w-2xl mx-auto">
            Choose your learning journey and master the art of smart investing
          </p>
        </motion.div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {products.map((product, index) => (
            <motion.div 
              key={product.id} 
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ 
                duration: 0.6, 
                delay: index * 0.1,
                type: "spring"
              }}
              className="group"
            >
              <div className="bg-white rounded-2xl shadow-lg overflow-hidden 
                            transition-all duration-300 hover:-translate-y-2 
                            hover:shadow-2xl border border-gray-100">
                <div className="relative">
                  <img 
                    src={product.imageUrl} 
                    alt={product.name} 
                    className="w-full h-60 object-cover transition-transform 
                             duration-500 group-hover:scale-105"
                  />
                  <div className="absolute top-4 right-4 bg-cyan-500 
                                text-white px-4 py-2 rounded-lg text-sm 
                                font-semibold flex items-center">
                    <Star className="w-4 h-4 mr-2 fill-yellow-300 text-yellow-300" />
                    Featured Course
                  </div>
                </div>
                
                <div className="p-8">
                  <h3 className="text-2xl font-bold mb-3 text-gray-800 group-hover:text-cyan-600 
                               transition-colors">
                    {product.name}
                  </h3>
                  <p className="text-gray-600 mb-6 line-clamp-2">{product.description}</p>
                  
                  <div className="space-y-4 mb-8">
                    <div className="flex items-center">
                      <TrendingUp className="w-5 h-5 text-green-500 mr-3" />
                      <span className="text-gray-700">
                        Success Rate: {(product.rewardPercentage * 100).toFixed(1)}%
                      </span>
                    </div>
                    <div className="flex items-center">
                      <Clock className="w-5 h-5 text-cyan-500 mr-3" />
                      <span className="text-gray-700">
                        Duration: {product.rewardDays} Days
                      </span>
                    </div>
                  </div>
                  
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => handleBuyNow(product)}
                    className="w-full bg-gradient-to-r from-cyan-500 to-blue-600 
                             text-white py-4 rounded-xl hover:from-cyan-600 
                             hover:to-blue-700 transition-all duration-300 
                             flex items-center justify-center font-semibold"
                  >
                    <ShoppingCart className="mr-2" /> Buy Now
                  </motion.button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </section>

      <AnimatePresence>
        {currentProduct && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/60 flex items-center justify-center z-50 
                     backdrop-blur-sm"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-white w-full max-w-md mx-4 rounded-2xl shadow-2xl"
            >
              <ProductPurchaseFlow product={currentProduct} />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <LivePurchaseNotifications products={products} />
    </div>
  );
};

export default ProductListingPage;